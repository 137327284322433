<template>
  <div class="box-element">
    <b-row class="top-box" ref="topBox">
      <b-col :cols="member ? 8 : 9">
        <div class="box-title">
          <h4>{{ title }}</h4>
        </div>
        <div class="box-value">
          <h3>{{ value }}</h3>
          <span class="subtitle" v-if="subtitle">{{ subtitle }}</span>
        </div>
      </b-col>
      <b-col cols="3" class="d-flex align-items-center justify-content-center p-0" v-if="!member">
        <div class="box-icon">
          <slot name="icon"></slot>
        </div>
      </b-col>
      <b-col
        :cols="member ? 4 : 3"
        class="d-flex align-items-center justify-content-center p-0 flex-column"
        v-else
      >
        <slot name="level"></slot>
        <div class="box-icon img-level">
          <slot name="icon"></slot>
        </div>
      </b-col>
    </b-row>
    <b-row class="box-bottom">
      <b-col cols="12" class="d-flex align-items-center justify-content-center px-0">
        <slot name="content"></slot>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    member: {
      type: [String],
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.box-element {
  padding: 1.25rem;
  margin-bottom: 20px;
  border: none;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  background-color: #fff;
  min-height: 200px;
  width: 100%;

  &:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 9px 12px rgba(0, 0, 0, 0.1);
  }
  .top-box {
    min-height: 80px;
  }
  .box-bottom {
    min-height: calc(200px - 80px - 1.25rem);
  }
  .box-title {
    h4 {
      margin-bottom: 8px;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      font-size: 15px;
      font-weight: 700;
      margin-top: 0;
      color: #6c757d;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    }
  }
  .box-value {
    h3 {
      color: #2f394e !important;
      font-weight: 600;
      margin-bottom: 0;
      font-size: clamp(18px, 3vw, 24px);
      @media (max-width: 425px) {
        font-size: clamp(20px, 3vw, 24px);
      }
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      color: #6c757d;
      margin-left: 5px;
      font-weight: 600;
      font-size: clamp(18px, 3vw, 24px);
      text-transform: capitalize;
      @media (max-width: 425px) {
        font-size: clamp(15px, 3vw, 19px);
      }
    }
  }
  .box-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 26px;
    z-index: 5;
    color: #fff;
    box-shadow: 0 0px 2px 5px rgba(0, 0, 0, 0.1), 0 0px 2px 5px rgba(0, 0, 0, 0.1);
    background: #FF5C00;
    @media (max-width: 1200px) {
      width: 40px;
      height: 40px;
    }
    &.img-level {
      background: transparent;
      box-shadow: none;
    }
  }
  .box-level {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span.level-name {
      margin-bottom: 0px;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      font-size: 15px;
      font-weight: 700;
      margin-top: 0;
      color: #6c757d;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    }
    .img-level {
      width: 75px;
      height: 75px;
      padding: 0px;
      background: transparent;
      box-shadow: none;
      filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.5));
      // border: 1px solid #34a849;
    }
  }
  .text-customer-1 {
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-size: 15px;
    font-weight: 700;
    margin-top: 0;
    color: #6c757d;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  }
}
</style>
