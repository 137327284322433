<template>
  <b-container fluid class="dashboard-page">
    <b-row>
      <b-col lg="4" cols="12">
        <BoxElement title="Total Deposit" value="$ 40000000" class="balance-card">
          <template v-slot:icon><i class="fas fa-money-check-edit-alt"></i></template>
          <template v-slot:content>
            <b-row class="box-info-content">
              <b-col cols="6" class="box-right">
                <div class="cash">$ 400</div>
                <div class="title">Total Deposit</div>
              </b-col>
              <b-col cols="6" class="box-left">
                <div class="cash">$ 400</div>
                <div class="title">Total Withdraw</div>
              </b-col>
            </b-row>
          </template>
        </BoxElement>
      </b-col>
      <b-col lg="4" cols="12">
        <BoxElement title="P&L statement" value="$ 40000" class="pnl-card">
          <template v-slot:icon><i class="fad fa-user-chart"></i></template>
          <template v-slot:content>
            <b-row class="box-info-content">
              <b-col cols="6" class="box-right">
                <div class="cash">$ 400</div>
                <div class="title">this weekly</div>
              </b-col>
              <b-col cols="6" class="box-left">
                <div class="cash">$ 400</div>
                <div class="title">Last Weekly</div>
              </b-col>
            </b-row>
          </template>
        </BoxElement>
      </b-col>
      <b-col lg="4" cols="12">
        <BoxElement title="Referral Link" subtitle="member" value="40" class="Referral-card">
          <template v-slot:icon>
            <i class="fad fa-users"></i>
          </template>
          <template v-slot:content>
            <div class="boxref mb-1">
              <span>Link Ref</span>
              <div class="ref-link">
                <b-input-group class="">
                  <template #prepend>
                    <b-input-group-text>
                      <b-button
                        v-clipboard:copy="url"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        >Copy</b-button
                      >
                    </b-input-group-text>
                  </template>
                  <b-form-input type="text" required v-model="url" readonly></b-form-input>
                </b-input-group>
              </div>
            </div>
          </template>
        </BoxElement>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

import BoxElement from '@/components/element/Box.vue';

export default {
  components: { BoxElement },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      UserInfo: 'user/UserInfo',
    }),
    url() {
      return `${window.location.hostname}/secure/register?sponsor=${this.UserInfo.id}`;
    },
  },
  methods: {
    onCopy() {
      this.$toastr.s('Copy Link Referral Success', 'Successfully');
    },
    onError() {
      this.$toastr.e('Copy Link Referral Fail,Please Try Again', 'Failed');
    },
  },
};
</script>

<style lang="scss">
.Referral-card {
  .ref-id {
    h4 {
      margin-bottom: 8px;
      text-transform: capitalize;
      letter-spacing: 0.02em;
      font-size: 18px;
      font-weight: 600;
      margin-top: 0;
      color: #6c757d;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
      span {
        font-size: 22px;
        color: #2f394e;
        font-weight: 600;
      }
    }
  }
  .boxref {
    width: 100%;
    span {
      font-size: 17px;
      color: #6c757d;
      font-weight: 600;
    }
    .ref-link {
      margin-top: 5px;
      background: #009750;
      border-radius: 5px;
      .input-group-text {
        padding: 0;
        border: 0;
        button {
          width: 70px;
          height: 40px;
          padding: 0;
          background: #8dc63f;
          border: 0;
          outline: none;
          border-radius: 5px 0 0 5px;
          box-shadow: 0px 0px 5px 1px #00000043;
          font-weight: 550;
        }
      }
      input {
        height: 40px;
        border: 0;
        background: transparent;
        color: #fff;
        font-weight: 500;
        border-radius: 0px 5px 5px 0px;
        &:hover,
        &:focus,
        &:focus-visible {
          outline: none;
          box-shadow: none;
          border: 0;
        }
      }
    }
  }
}
.balance-card,
.pnl-card {
  .box-info-content {
    width: 100%;
    height: 100%;
    align-items: flex-end;
    .box-right,
    .box-left {
      flex: 0 0 50%;
      max-width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      .cash {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2f394e !important;
        font-weight: 600;
        font-size: clamp(19px, 2vw, 20px);
      }
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6c757d;
        margin-left: 5px;
        font-weight: 600;
        font-size: clamp(0.7rem, 2vw, 0.8rem);
        text-transform: capitalize;
      }
    }
    .box-left {
    }
    .box-right {
    }
  }
}
</style>
